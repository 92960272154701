import type { Dispatch } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-apollo';

import { isStatusCodeError } from '@confluence/error-types';
import { markErrorAsHandled } from '@confluence/graphql';

import { Status } from '../constants/Status';
import { DefaultAndSpaceClassificationLevelsQuery } from '../queries/DefaultAndSpaceClassificationQuery.graphql';
import type { ClassificationLevel } from '../ClassificationRadioOption';
import {
	NO_CLASSIFICATION_OPTION,
	NO_CLASSIFICATION_OPTION_ID,
} from '../constants/NoClassification';

/**
 * Return type for useDefaultAndSpaceClassificationLevels hook. Includes:
 * 1) Classification levels available in a space
   2) Space's default classification
   3) Other space classification metadata (e.g. space name, total pages in space )
 */
export type SpaceClassificationMetadata = {
	classificationLevels: ClassificationLevel[];
	defaultClassification: ClassificationLevel | null;
	defaultId: string;
	loading: boolean;
	isEnabled: boolean;
	expectedError?: boolean;
	spaceName: string;
	totalCurrentPages: number;
	setUnfilteredClassificationId: Dispatch<string>;
};

/**
 *
 * @param isAdmin
 * @param setClassificationsEnabledForSpace
 * @returns
 * 1) Classification levels available in a space
   2) Space's default classification
   3) Other space classification metadata (e.g. space name, total pages in space )
 */
export const useDefaultAndSpaceClassificationLevels = (
	spaceKey: string,
	isAdmin?: boolean,
	setClassificationsEnabledForSpace?: Dispatch<boolean>,
) => {
	const { data, loading, error } = useQuery(DefaultAndSpaceClassificationLevelsQuery, {
		variables: { spaceKey },
		// Skip query if there's no spaceKey, or if user is not an admin (isAdmin is only passed when the hook is called from space settings)
		skip: !spaceKey || isAdmin === false,
		errorPolicy: 'all',
		fetchPolicy: 'network-only',
	});

	// If there was a 404 status error,
	// then classifications are not enabled for that space
	const isEnabled = !error || (error && !error?.message.includes('Feature is disabled'));
	if (setClassificationsEnabledForSpace) setClassificationsEnabledForSpace(isEnabled);

	// If no default classification is set for the space, then we want
	// to send a fallback No Classification option instead of throwing an error
	const noDefaultClassification = error?.message.includes(
		'No default classification set for space with ID',
	);

	const expectedError =
		(error && !noDefaultClassification && isStatusCodeError(error, '404')) ||
		isStatusCodeError(error, '500') ||
		isStatusCodeError(error, '400');

	// Mark error as handled if 1) feature is not enabled, 2) there is no default
	// classification set, or 3) there was another kind of expected error
	if (!isEnabled || noDefaultClassification || expectedError) {
		markErrorAsHandled(error);
	}

	// Classification levels to display - ordered, and filtered by status
	// (to only display published levels)
	const classificationLevels: ClassificationLevel[] =
		loading || expectedError || !data
			? []
			: data?.['classificationLevels']
					.filter(
						(classification: ClassificationLevel) => classification.status === Status.PUBLISHED,
					)
					.sort(
						(
							a: ClassificationLevel & { order: string },
							b: ClassificationLevel & { order: string },
						) => a.order - b.order,
					);

	const defaultId = noDefaultClassification
		? NO_CLASSIFICATION_OPTION_ID
		: data?.space?.['defaultClassificationLevelId'];

	const [unfilteredClassificationId, setUnfilteredClassificationId] = useState(defaultId);

	if (!unfilteredClassificationId && defaultId) {
		setUnfilteredClassificationId(defaultId);
	}

	const spaceName = data?.space?.name;
	const totalCurrentPages = data?.space?.metadata.totalCurrentPages;

	// Find default classification from unfiltered list,
	// in case classification is not published
	const defaultClassification = noDefaultClassification
		? NO_CLASSIFICATION_OPTION
		: data?.['classificationLevels']?.find(
				(level: ClassificationLevel) => level.id === unfilteredClassificationId,
			);

	return {
		classificationLevels,
		defaultClassification,
		defaultId,
		loading,
		isEnabled,
		expectedError,
		spaceName,
		totalCurrentPages,
		setUnfilteredClassificationId,
	};
};
