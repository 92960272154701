import { useCallback } from 'react';

import type { ClassificationOption } from '@atlassian/data-classification-level';

import { ContentStatus } from '../constants/ContentStatus';
import { FlagStatus } from '../constants/FlagStatus';
import { NO_CLASSIFICATION_OPTION_ID } from '../constants/NoClassificationOptionId';
import { SPACE_DEFAULT_CLASSIFICATION_OPTION_ID } from '../constants/SpaceDefaultClassificationOptionId';
import { ContentDataClassificationMutationContentStatus } from '../queries/__types__/BulkUpdateContentDataClassificationLevelMutation';
import { getReadAndWriteContextFromQueryContext } from '../utils/getReadAndWriteContextFromQueryContext';

import type { ContentClassificationMetadata } from './useContentClassification';

type UseEditSubmitCallbackArguments = {
	contentId: string;
	contentClassificationMetadata: ContentClassificationMetadata;
};

export const getMutationContentStatusFromContentStatus = (contentStatus: ContentStatus) => {
	switch (contentStatus) {
		case ContentStatus.DRAFT:
			return ContentDataClassificationMutationContentStatus.DRAFT;
		default:
			return ContentDataClassificationMutationContentStatus.CURRENT;
	}
};

export const useEditSubmitCallback = ({
	contentId,
	contentClassificationMetadata,
}: UseEditSubmitCallbackArguments) => {
	const {
		setSubmittedClassification,
		setFlagStatus,
		updateContentDataClassificationLevel: updateClassificationMutation,
		deleteContentDataClassificationLevel: deleteClassificationMutation,
		contentStatusContext,
	} = contentClassificationMetadata;

	return useCallback(
		(classificationOption: ClassificationOption) => {
			// Provides type safety for the mutation variables
			if (!classificationOption?.id) return;

			setFlagStatus(FlagStatus.LOADING);

			const { writeContext: contentStatusWriteContext } = getReadAndWriteContextFromQueryContext(
				contentStatusContext ?? 'current-only',
			);
			const contentStatuses = contentStatusWriteContext.map(
				getMutationContentStatusFromContentStatus,
			);

			const baseVariables = {
				id: parseInt(contentId),
				contentStatuses,
			};

			const optionIsNoClassificationOption =
				classificationOption?.id === NO_CLASSIFICATION_OPTION_ID;
			const optionIsSpaceDefaultClassificationOption =
				classificationOption?.id === SPACE_DEFAULT_CLASSIFICATION_OPTION_ID;

			const shouldDeleteClassification =
				optionIsNoClassificationOption || optionIsSpaceDefaultClassificationOption;

			if (shouldDeleteClassification) {
				if (optionIsNoClassificationOption) setSubmittedClassification(null);
				if (optionIsSpaceDefaultClassificationOption) {
					setSubmittedClassification(classificationOption?.classificationOverride ?? null);
				}

				void deleteClassificationMutation({ variables: baseVariables });
			} else {
				setSubmittedClassification(classificationOption);

				void updateClassificationMutation({
					variables: {
						...baseVariables,
						classificationLevelId: classificationOption.id,
					},
				});
			}
		},
		[
			contentId,
			setFlagStatus,
			contentStatusContext,
			updateClassificationMutation,
			deleteClassificationMutation,
			setSubmittedClassification,
		],
	);
};
