import React, { createContext, useContext, useMemo, useState, type ReactNode } from 'react';

type ClassificationModalContextValue = {
	isClassificationModalOpen: boolean;
};

type ClassificationModalDispatchContextType = {
	setIsClassificationModalOpen: (value: boolean) => void;
};

const ClassificationModalContext = createContext<ClassificationModalContextValue>({
	isClassificationModalOpen: false,
});

ClassificationModalContext.displayName = 'ClassificationModalContext';

export const ClassificationModalDispatchContext =
	createContext<ClassificationModalDispatchContextType>({
		setIsClassificationModalOpen: () => {},
	});

ClassificationModalDispatchContext.displayName = 'ClassificationModalDispatchContext';

export const ClassificationModalContextProvider = ({ children }: { children: ReactNode }) => {
	const [isClassificationModalOpen, setIsClassificationModalOpen] = useState<boolean>(false);

	const contextValue = useMemo<ClassificationModalContextValue>(
		() => ({
			isClassificationModalOpen,
		}),
		[isClassificationModalOpen],
	);

	const dispatchContextValue = useMemo<ClassificationModalDispatchContextType>(
		() => ({
			setIsClassificationModalOpen: (value) => setIsClassificationModalOpen(value),
		}),
		[setIsClassificationModalOpen],
	);

	return (
		<ClassificationModalContext.Provider value={contextValue}>
			<ClassificationModalDispatchContext.Provider value={dispatchContextValue}>
				{children}
			</ClassificationModalDispatchContext.Provider>
		</ClassificationModalContext.Provider>
	);
};

export const useClassificationModalContext = () => {
	return useContext(ClassificationModalContext);
};

export const useClassificationModalDispatchContext = () => {
	return useContext(ClassificationModalDispatchContext);
};
