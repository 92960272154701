import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import { useSpaceId } from '@confluence/space-utils';

type GetAnalyticsEventArgs = {
	cloudId: string;
	spaceId: string;
	orgId: string;
	actionSubjectId?: string;
};

export enum ActionSubjectId {
	EDITOR_TITLE_DATA_CLASSIFICATION_POPUP = 'editorTitleDataClassificationPopup',
	CONTENT_TYPE_TITLE_DATA_CLASSIFICATION_POPUP = 'contentTypeTitleDataClassificationPopup',
	GENERIC_DATA_CLASSIFICATION_MODAL_POPUP = 'genericDataClassificationModalPopup',
}

const getEditPopupViewedScreenEvent = ({
	cloudId,
	spaceId,
	orgId,
	actionSubjectId,
}: GetAnalyticsEventArgs): AnalyticsEventPayload => ({
	type: 'sendScreenEvent',
	data: {
		name: 'dataClassificationEditPopup',
		action: 'viewed',
		actionSubject: 'popup',
		actionSubjectId,
		attributes: {
			cloudId,
			spaceId,
			orgId,
		},
	},
});

export const useTrackClassificationAnalytics = () => {
	const { cloudId, orgId } = useSessionData();
	const spaceId = useSpaceId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return {
		trackEditPopupViewed: (actionSubjectId: ActionSubjectId) => {
			if (!spaceId || !cloudId || !orgId) return;
			createAnalyticsEvent(
				getEditPopupViewedScreenEvent({
					cloudId,
					spaceId,
					orgId,
					actionSubjectId,
				}),
			).fire();
		},
	};
};
