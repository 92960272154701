import { fg } from '@atlaskit/platform-feature-flags';

interface UseIsEllipsisClassifyEnabledI {
	isEllipsisClassifyEnabled: boolean;
}

export const useIsEllipsisClassifyEnabled = (): UseIsEllipsisClassifyEnabledI => {
	return {
		isEllipsisClassifyEnabled: fg('dlp_cc-ellipsis-classify-content'),
	};
};
