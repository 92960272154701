import React from 'react';
import { useIntl } from 'react-intl-next';

import type { ClassificationOption } from '@atlassian/data-classification-level';
import { EditPopup } from '@atlassian/data-classification-level';

import type { ClassificationLevel } from '../ClassificationRadioOption';
import { useDefaultAndSpaceClassificationLevels } from '../hooks/useDefaultAndSpaceClassificationLevels';
import { getAllClassificationLevels } from '../utils/getAllClassificationLevels';

import { sharedMessages } from './sharedMessages';

type EditViewProps = {
	spaceKey: string;
	defaultOption: ClassificationOption;
	onEditCancel: () => void;
	onEditSubmit: (classification: ClassificationOption) => void;
	selectedOption?: ClassificationOption;
	loading?: boolean;
	hasError?: boolean;
};

export const ClassificationEditViewPopup = ({
	spaceKey,
	defaultOption,
	onEditCancel,
	onEditSubmit,
	selectedOption,
	loading = false,
}: EditViewProps) => {
	const { formatMessage } = useIntl();

	const { classificationLevels, expectedError } = useDefaultAndSpaceClassificationLevels(spaceKey);

	const allClassificationLevels: ClassificationLevel[] = [
		...getAllClassificationLevels(classificationLevels),
		defaultOption,
	];

	return (
		<EditPopup
			options={allClassificationLevels}
			selectedOption={selectedOption}
			onEditCancel={onEditCancel}
			onEditSubmit={onEditSubmit}
			loading={loading}
			errorMessage={expectedError ? formatMessage(sharedMessages.fetchErrorTextPrimary) : undefined}
		/>
	);
};
