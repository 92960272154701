import { useQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { fg } from '@atlaskit/platform-feature-flags';

import { markErrorAsHandled } from '@confluence/graphql';
import { getStatusCode } from '@confluence/error-boundary';

import type { OrgDefaultClassificationLevelIdQuery as OrgDefaultClassificationLevelIdQueryType } from '../queries/__types__/OrgDefaultClassificationLevelIdQuery';
import { OrgDefaultClassificationLevelIdQuery } from '../queries/OrgDefaultClassificationLevelIdQuery.graphql';

type UseOrgDefaultClassificationLevelIdProps = {
	skip?: boolean;
};

type UseOrgDefaultClassificationLevelIdType = {
	orgDefaultClassificationLevelId: string | null;
	isOrgDefaultClassificationEnabled: boolean;
	orgDefaultClasificationLoading: boolean;
	orgDefaultClassificationError?: ApolloError;
};

const shouldMarkErrorAsHandled = (error?: ApolloError) => {
	if (!error) return true;
	const statusCode = getStatusCode(error?.graphQLErrors?.[0]);
	if (!statusCode) return true;
	return !!(parseInt(statusCode) >= 400 && parseInt(statusCode) < 500);
};

export const useOrgDefaultClassificationLevelId = ({
	skip,
}: UseOrgDefaultClassificationLevelIdProps): UseOrgDefaultClassificationLevelIdType => {
	const { data, loading, error } = useQuery<OrgDefaultClassificationLevelIdQueryType>(
		OrgDefaultClassificationLevelIdQuery,
		{ skip: !fg('dlp_cc-org-level-classifications') || skip },
	);

	if (shouldMarkErrorAsHandled(error)) {
		markErrorAsHandled(error);
	}

	const orgDefaultClassificationLevelId = data?.orgDefaultClassificationLevelId ?? null;

	return {
		orgDefaultClassificationLevelId,
		isOrgDefaultClassificationEnabled: fg('dlp_cc-org-level-classifications'),
		orgDefaultClasificationLoading: loading,
		orgDefaultClassificationError: !shouldMarkErrorAsHandled(error) ? error : undefined,
	};
};
