import React, { useCallback, useEffect } from 'react';

import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import type { ClassificationOption } from '@atlassian/data-classification-level';

import { ErrorDisplay } from '@confluence/error-boundary';

import type { ContentClassificationMetadata } from '../hooks/useContentClassification';
import {
	useClassificationModalContext,
	useClassificationModalDispatchContext,
} from '../contexts/ClassificationModalContext';
import { useEditSubmitCallback } from '../hooks/useEditSubmitCallback';
import { useComputeDefaultClassificationDropdownOption } from '../hooks/useComputeDefaultClassificationDropdownOption';
import {
	ActionSubjectId,
	useTrackClassificationAnalytics,
} from '../hooks/useTrackClassificationAnalytics';

import { ClassificationEditViewPopup } from './ClassificationEditViewPopup';

type ClassificationEditModalProps = {
	contentId: string;
	spaceKey: string;
	contentClassificationMetadata: ContentClassificationMetadata;
};

export const ClassificationEditModal = ({
	contentId,
	spaceKey,
	contentClassificationMetadata,
}: ClassificationEditModalProps) => {
	const { isClassificationModalOpen } = useClassificationModalContext();
	const { setIsClassificationModalOpen } = useClassificationModalDispatchContext();
	const { trackEditPopupViewed } = useTrackClassificationAnalytics();

	const handleSubmit = useEditSubmitCallback({
		contentId,
		contentClassificationMetadata,
	});

	const {
		classification,
		spaceClassification,
		spaceDefaultIsOverridden,
		getClassificationLevelFromId,
	} = contentClassificationMetadata;

	const { defaultClassificationOption, error: classificationOptionError } =
		useComputeDefaultClassificationDropdownOption({
			getClassificationLevelFromId,
			spaceDefaultClassificationLevel: spaceClassification,
		});

	const defaultSelectedEditOption = spaceDefaultIsOverridden
		? classification
		: defaultClassificationOption;

	const onEditSubmit = useCallback(
		(classificationOption: ClassificationOption) => {
			handleSubmit(classificationOption);
			setIsClassificationModalOpen(false);
		},
		[handleSubmit, setIsClassificationModalOpen],
	);

	const onEditCancel = useCallback(() => {
		setIsClassificationModalOpen(false);
	}, [setIsClassificationModalOpen]);

	useEffect(() => {
		if (isClassificationModalOpen) {
			trackEditPopupViewed(ActionSubjectId.GENERIC_DATA_CLASSIFICATION_MODAL_POPUP);
		}
	}, [isClassificationModalOpen, trackEditPopupViewed]);

	return (
		<ModalTransition>
			{isClassificationModalOpen && (
				<Modal onClose={() => setIsClassificationModalOpen(false)} width="330px">
					{classificationOptionError ? <ErrorDisplay error={classificationOptionError} /> : null}
					<ClassificationEditViewPopup
						onEditSubmit={onEditSubmit}
						onEditCancel={onEditCancel}
						selectedOption={defaultSelectedEditOption ?? undefined}
						defaultOption={defaultClassificationOption}
						spaceKey={spaceKey}
					/>
				</Modal>
			)}
		</ModalTransition>
	);
};
