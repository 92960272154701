import React from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import type { Appearance } from '@atlaskit/section-message';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';

const descriptionContainerStyles = xcss({
	paddingTop: 'space.100',
});

type MessageProps = {
	appearance: Appearance;
	title?: MessageDescriptor;
	description?: MessageDescriptor;
	action?: MessageDescriptor;
};

export const FormattedSectionMessage = ({
	appearance,
	title,
	description,
	action,
}: MessageProps) => {
	const intl = useIntl();
	return (
		<SectionMessage
			appearance={appearance}
			title={title && intl.formatMessage(title)}
			actions={
				action && (
					<SectionMessageAction onClick={() => window.location.reload()}>
						<FormattedMessage {...action} />
					</SectionMessageAction>
				)
			}
		>
			{description && (
				<>
					{title ? (
						<Box xcss={descriptionContainerStyles}>
							<FormattedMessage {...description} />
						</Box>
					) : (
						<FormattedMessage {...description} />
					)}
				</>
			)}
		</SectionMessage>
	);
};
