/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContentDataClassificationMutationContentStatus {
  CURRENT = "CURRENT",
  DRAFT = "DRAFT",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkUpdateContentDataClassificationLevel
// ====================================================

export interface BulkUpdateContentDataClassificationLevel_bulkUpdateContentDataClassificationLevel_errors {
  message: string | null;
}

export interface BulkUpdateContentDataClassificationLevel_bulkUpdateContentDataClassificationLevel {
  success: boolean;
  errors: BulkUpdateContentDataClassificationLevel_bulkUpdateContentDataClassificationLevel_errors[] | null;
}

export interface BulkUpdateContentDataClassificationLevel {
  bulkUpdateContentDataClassificationLevel: BulkUpdateContentDataClassificationLevel_bulkUpdateContentDataClassificationLevel;
}

export interface BulkUpdateContentDataClassificationLevelVariables {
  id: any;
  contentStatuses: (ContentDataClassificationMutationContentStatus | null)[];
  classificationLevelId: string;
}
