import type { ClassificationLevel } from '../ClassificationRadioOption';

/**
 *
 * @param classificationLevels
 * @returns classification levels for classifications dropdown in the Edit Classification modal in the editor hover toolbar.
 **/
export const getAllClassificationLevels = (
	classificationLevels: ClassificationLevel[],
): ClassificationLevel[] => {
	// Data classification dropdown in edit popup accepts definition property for displaying descriptions per classification
	const classificationLevelsWithDefinitions = classificationLevels.map(
		(level) =>
			({
				...level,
				definition: level.description,
			}) as ClassificationLevel,
	);

	return [...classificationLevelsWithDefinitions];
};
