import { useIsDataClassificationEnabledForOrg } from './useIsDataClassificationEnabledForOrg';

const isContentTypeApplicable = (contentType: string, isLivePage: boolean = false) => {
	if (contentType === 'page' && isLivePage) return true;
	return ['whiteboard', 'database'].includes(contentType);
};

export const useIsClassificationEnabledForContentTypeExpansion = (
	contentType: string,
	isLivePage: boolean = false,
): boolean => {
	const { isEnabled: isClassificationEnabledForOrg } = useIsDataClassificationEnabledForOrg();

	return isContentTypeApplicable(contentType, isLivePage) && isClassificationEnabledForOrg;
};
